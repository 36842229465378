<template>
  <section class="calendars">
    <div class="calendars__section">
      <p class="calendars__section-title">
        {{ t('common.form_calendar_section.select_region') }}
      </p>
      <div class="calendars__controls">
        <FormBaseRadioButton
          v-model="region"
          radio-group-id="calendars-region"
          :label="t('common.form_calendar_section.region_eu')"
          value="eu"
          size="s"
        />
        <FormBaseRadioButton
          v-model="region"
          radio-group-id="calendars-region"
          :label="t('common.form_calendar_section.region_us')"
          value="us"
          size="s"
        />
        <FormBaseRadioButton
          v-model="region"
          radio-group-id="calendars-region"
          :label="t('common.form_calendar_section.region_au')"
          value="au"
          size="s"
        />
      </div>
    </div>
    <div class="calendars__section" id="form-calendar-section">
      <p class="calendars__section-title">
        {{ t('common.form_calendar_section.choose_date') }}
      </p>
      <HsIframeForm
        v-for="reg in Object.keys(hsCalendarLinks)"
        :key="reg"
        :src="hsCalendarLinks[reg as regions]"
        v-show="reg === region"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
type regions = 'eu' | 'us' | 'au'

const region = ref<regions>('eu')

const hsCalendarLinks = {
  eu: 'https://meetings.hubspot.com/jablonska/sdr-calendar-karolina?embed=true',
  us: 'https://meetings.hubspot.com/ronald-owen?embed=true',
  au: 'https://meetings.hubspot.com/julia-zebrowska/sdr-calendar-julia-?embed=true',
}

const { t } = useI18n()
const { scrollToElement } = useScrollToElement()

watch(region, () => {
  scrollToElement('form-calendar-section')
})
</script>

<style lang="scss" scoped>
.calendars {
  position: relative;

  &__controls {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 400px;
  }

  &__section-title {
    text-align: left;
    font-weight: 600;
    display: block;
    font-size: var(--font-size-paragraph-s);
  }

  &__section {
    margin-bottom: 56px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
